

export function addLoader(container, absolute) {
    const loader = document.createElement('div');
    loader.classList.add('loader');
    // Adding the dots
    for (let i = 0; i < 4; i++) {
        const div = document.createElement('div');
        loader.appendChild(div);
    }
    // Add loader to element
    container.appendChild(loader);
    container.classList.add("loading");

    if (absolute) {
        container.classList.add("loading--center");
    }
}

export function removeLoader(container) {
    const loader = container.querySelector(".loader");

    if (loader) {
        container.removeChild(loader);
        container.classList.remove("loading");
        container.classList.remove("loading--center");
    }
}
