function toggleReadMore(e) {
    const classTarget = e.currentTarget;
    const moreTextElem = classTarget.nextElementSibling;

    e.preventDefault();

    if (classTarget.classList.contains('open')) {
        classTarget.classList.remove('open');
        moreTextElem.style.height = '0px';
    } else {
        classTarget.classList.add('open');
        moreTextElem.style.height = `${moreTextElem.scrollHeight}px`;
    }
}

export function initReadMore(selector, scopeElement = document.body) {
    const readMore = scopeElement.querySelectorAll(selector);
    for (let i = 0; i < readMore.length; i++) {
        const readMoreElement = readMore[i];
        readMoreElement.addEventListener('click', toggleReadMore);
    }
}
