import {breakpointKeys, currentBreakpoint} from "../utils/windowResize";
import {onWindowResize} from "./windowResize";
import 'promise-polyfill/src/polyfill';


const loadedClassname = "image--loaded";
let lastUsedScreenWidth;
const lazy = {};

/**
 * Try to decode the image, after it's loaded, and resolve the Promise.
 *
 * @param {Element} newImage
 * @returns {Promise<Image>}
 */
function decodeImage(newImage) {
    return ("decode" in newImage) ?
        newImage.decode().then(() => newImage) :
        Promise.resolve(newImage);
}

/**
 * Load an image, and return a Promise that resolves once the image is loaded.
 *
 * @param {string} path
 * @returns {Promise<Image>} Promise that will resolve with the loaded image once it's ready.
 */
function loadImage(path) {
    const newImage = new Image();

    return new Promise(resolve => {
        newImage.addEventListener("load", () => decodeImage(newImage).then(image => resolve(image)), false);
        newImage.src = path;
    });
}

/**
 * This function gets the data-src from the image wrapper, based on width of the browser window.
 *
 * @param {HTMLElement} container - Image wrapper element
 * @returns {string}
 */
function getImageSrc(container) {

    let src = "";
    let largestBreakpointFound = 0;

    breakpointKeys.forEach((breakpointName, index) => {
        if (currentBreakpoint >= index) {
            if (index === 0 || index > largestBreakpointFound) {
                src = container.getAttribute(`data-src-${breakpointName}`) || src;

                // Make sure we won't set the size to a smaller breakpoint later, in case they're not properly ordered.
                largestBreakpointFound = index;
            }
        }
    });

    return src;
}

/**
 * This function gets the image wrapper data attributes src and alt text
 * and creates an new image tag to download the image.
 * It then uses the src as a background-image.
 *
 * @param {HTMLElement} bgContainer - Image wrapper element
 */
export function loadBgImage(bgContainer) {

    const src = getImageSrc(bgContainer);

    // If no usable source was returned, abort at once.
    if (!src) {
        return;
    }

    const formattedSrc = `url(${src})`;

    if (bgContainer.style.backgroundImage === formattedSrc) {
        return;
    }
    // Start loading the new image.
    loadImage(src).then(() => {
        bgContainer.style.backgroundImage = formattedSrc;
        bgContainer.classList.add(loadedClassname);
    });
}

/**
 * This function gets the image wrapper data attributes src and alt text
 * and creates an new image tag to download the image.
 * It then inserts the image into the given container.
 *
 * @param {HTMLElement} container - Image wrapper element
 */
export function loadLazyImage(container) {

    const src = getImageSrc(container);

    // If no usable source was returned, abort mission.
    if (!src) {
        return;
    }

    // We don't want to start processing if the new URL matches the old one.
    const oldImage = container.querySelector('img');
    if (oldImage && container.classList.contains(loadedClassname)) {
        if (oldImage.getAttribute("src") === src) {
            return;
        } else {
            container.removeChild(oldImage);
        }
    }
    // Start loading the new image.
    loadImage(src).then(newImageTag => {
        // Set the ALT text.
        const altText = container.getAttribute("data-alt") || "";
        newImageTag.setAttribute("alt", altText);

        container.appendChild(newImageTag);
        container.classList.add(loadedClassname);
    });
}

/**
 *
 * @private
 */
function refreshAll () {
    // If our current screen mode does not match the one we used the last time we made an image lookup,
    // perform a new one now. Otherwise, what would be the point?
    if (lastUsedScreenWidth < currentBreakpoint) {
        for (let i = 0; i < lazy.images.length; i++) {
            const lazyImage = lazy.images[i];
            loadLazyImage(lazyImage);
        }
        for (let i = 0; i < lazy.bgImages.length; i++) {
            const lazyBgImage = lazy.bgImages[i];
            loadBgImage(lazyBgImage);
        }
        lastUsedScreenWidth = currentBreakpoint;
    }
}


/**
 * Load all responsive images on the page.
 */
export function loadAllLazyImages() {
    lazy.images = document.querySelectorAll(".lazy-image");
    lazy.bgImages = document.querySelectorAll(".lazy-bg-image");
    lastUsedScreenWidth = -1;
    refreshAll();
    onWindowResize(refreshAll);
}
