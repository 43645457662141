import {breakpoints, currentWindowWidth} from "../utils/windowResize";

let solutionImages;
let activeImage;

function solutionHover(e, boxIndex) {

    const img = solutionImages[boxIndex + 1];

    activeImage.classList.remove('active');

    if (e.type === 'mouseenter') {
        img.classList.add('active');
        activeImage = img;
    } else {
        activeImage = solutionImages[0];
        activeImage.classList.add('active');
    }
}

export function activateSolutionHover(selector, container = document) {
    const solutions = container.querySelector(selector);

    if (solutions && currentWindowWidth >= breakpoints.lg) {
        const solutionBoxes = container.querySelectorAll('.section-solutions__box');
        solutionImages = solutions.querySelectorAll('.media--bg-wrap .lazy-bg-image');

        activeImage = solutionImages[0];

        for (let i = 0; i < solutionBoxes.length; i++) {
            const box = solutionBoxes[i];

            box.addEventListener('mouseenter', e => solutionHover(e, i));
            box.addEventListener('mouseleave', e => solutionHover(e, i));
        }
    }

}
