import {breakpoints, currentWindowWidth} from "../utils/windowResize";

let closeTimer;

function searchClassToggle(e) {
    const navSearch = e.currentTarget.parentNode;
    const navInputField = navSearch.querySelector('input');

    clearTimeout(closeTimer);

    if (!navInputField.value.trim()) {
        e.preventDefault();
        e.stopPropagation();

        if (navSearch.classList.contains('open')) {
            navSearch.classList.remove('open');
            navInputField.setAttribute('tabindex', -1);
        } else {
            navSearch.classList.add('open');
            navInputField.removeAttribute('tabindex');
            navInputField.focus();
        }
    }
}

export function setupSearch(container) {
    const navSearch = container.querySelector('.nav__tool__search');
    if (navSearch) {
        navSearch.querySelector('input').value = '';
        navSearch.querySelector('button').addEventListener('click', searchClassToggle);

        if (currentWindowWidth < breakpoints.md) {
            navSearch.querySelector('input').addEventListener('blur', () => {
                closeTimer = setTimeout(() => {
                    navSearch.classList.remove('open');
                }, 100);
            });
        }
    }
}
