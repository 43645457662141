import {breakpoints, currentWindowWidth} from "../utils/windowResize";
import {toggleClass} from "../utils/helpers";
import {hideOnClickOutside} from "../utils/clickOutside";

let nav;
let navMenu;
let activeList;
const openNavClass = 'nav--open';
const activeClass = 'active';
const activeOpenClass = 'active--open';


function disableNavMenu(resetHeight = false) {

    if (activeList) {
        activeList.removeAttribute('style');
        activeList.classList.remove(activeClass);
        activeList.classList.remove(activeOpenClass);
        activeList = false;
    }
    navMenu.classList.remove('nav-menu--open');
    navMenu.removeAttribute('style');

    if (resetHeight) {
        navMenu.style.height = navMenu.getAttribute('data-height');
    }
}

function toggleMenuOpen() {
    if (nav.classList.contains(openNavClass)) {
        nav.classList.remove(openNavClass);
        setTimeout(disableNavMenu, 300);
    } else {
        nav.classList.add(openNavClass);
        navMenu.style.height = `${navMenu.scrollHeight}px`;
    }
}

function toggleActiveList(e) {
    const targetParent = e.currentTarget.parentNode;

    if (e.currentTarget.getAttribute("role") === "button") {
        e.preventDefault();
    } else {
        return;
    }

    if (targetParent.classList.contains(activeClass)) {
        disableNavMenu(true);
    } else {
        targetParent.classList.add(activeClass);

        if (activeList) {
            activeList.classList.remove(activeClass);
            activeList.classList.remove(activeOpenClass);
        } else navMenu.setAttribute('data-height', navMenu.style.height);

        hideOnClickOutside(navMenu, () => disableNavMenu(true));

        navMenu.classList.add('nav-menu--open');
        activeList = targetParent;
        activeList.style.top = `${activeList.offsetTop}px`;
        activeList.setAttribute('data-offset', `${activeList.offsetTop}px`);

        setTimeout(() => {
            activeList.classList.add(activeOpenClass);
            activeList.removeAttribute('style');
            navMenu.style.height = `${activeList.scrollHeight}px`;
        }, 250);
    }
}



export function setupNav(container) {
    if (!nav) {
        nav = container;
        navMenu = nav.querySelector('.nav-menu');
    }

    const navBtn = nav.querySelector('.nav-btn');
    const navLang = nav.querySelector('.nav__tool__lang');
    const navLinks = navMenu.querySelectorAll('.nav-menu__head');
    // Function literal made to be able to remove eventListener
    const langClassToggle = e => {
        e.preventDefault();
        toggleClass(e, navLang);
    };

    for (let i = 0; i < navLinks.length; i += 1) {
        const navLink = navLinks[i];
        navLink.addEventListener('click', toggleActiveList);
    }

    if (currentWindowWidth >= breakpoints.md) {
        nav.classList.remove(openNavClass);
        navBtn.removeEventListener('click', toggleMenuOpen);
        navLang.removeEventListener('click', langClassToggle);
    } else {
        navBtn.addEventListener('click', toggleMenuOpen);
        navLang.querySelector('.active').addEventListener('click', langClassToggle);
    }
}
