
export const transitionEndEventName = detactTransitionEndEventName();

export function toggleClass(e, classTarget = e.currentTarget, className = 'open') {
    classTarget.classList.toggle(className);
}

export function detactTransitionEndEventName() {

    const transitions = {
        "transition": "transitionend",
        "OTransition": "oTransitionEnd",
        "MozTransition": "transitionend",
        "WebkitTransition": "webkitTransitionEnd"
    };

    const el = document.createElement("div");

    for (const t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
    return "";
}
