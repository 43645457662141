import {transitionEndEventName} from "../utils/helpers";

export function setupVimeoIframeBg(selector, container = document) {
    const mediaWrap = container.querySelector(selector);

    if (mediaWrap) {
        const iframe = mediaWrap.querySelector('iframe') || document.createElement('iframe');
        const vimeoId = mediaWrap.getAttribute("data-vimeoid");
        const vimeoStartTime = mediaWrap.getAttribute("data-start-time") || '0s';

        if (!iframe.src) {
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('allowtransparency', 'true');
            iframe.setAttribute('playsinline', 'true');

            const imageElem = mediaWrap.querySelector('img');

            if (imageElem) {

                iframe.addEventListener('load', function () {
                    this.parentNode.classList.add('video-loaded');
                });

                imageElem.addEventListener(transitionEndEventName, function imageTransitionEnd() {
                    this.parentNode.classList.add('image-visibile');
                    this.removeEventListener(transitionEndEventName, imageTransitionEnd);
                });
            } else {
                iframe.addEventListener('load', function () {
                    this.parentNode.classList.add('video-loaded');
                    this.parentNode.classList.add('image-visibile');
                });
            }

            iframe.src = `https://player.vimeo.com/video/${vimeoId}?background=1&dnt=1#t=${vimeoStartTime}`; //Todo remember to remove #t hash if not needed
            mediaWrap.appendChild(iframe);
        }
    }
}