import {initWindowResize, onWindowResize} from "./utils/windowResize";
import {setupNav} from './modules/navigation';
import {setupSearch} from './modules/search';
import {setupVimeoIframeBg} from "./modules/video";
import {activateSolutionHover} from "./modules/solutions";
import {loadAllLazyImages} from "./utils/lazyImage";
import {initForm} from "./modules/form";
import {initReadMore} from "./modules/readmore";

const body = document.body;

function init() {
    initWindowResize();

    //Remove loading class to start animations
    body.classList.remove('loading');

    // Setup top/mobile navigation functionality
    const nav = body.querySelector('.nav');
    setupNav(nav);
    setupSearch(nav); // nav used as query container to save DOM lookup
    onWindowResize(setupNav);

    loadAllLazyImages();

    // Load and setup video backgrounds
    setupVimeoIframeBg('.header__media[data-vimeoid]', body);

    window.addEventListener('load', () => {
        activateSolutionHover('.section-solutions', body);
        initReadMore('.read-more__btn', body);

        initForm('contact');

        // Setup click-event on product-images.
        // this is to avoid doublet-links
        const productImages = body.querySelectorAll(".section-products .lazy-image");

        for (let i = 0; i < productImages.length; i++) {
            const productImage = productImages[i];
            const productWrap = productImage.parentNode;

            const goToProduct = () => {
                const readMoreBtn = productWrap.querySelector(".btn--readmore");

                if (readMoreBtn) {
                    window.location = readMoreBtn.href;
                }
            };

            productImage.addEventListener("click", goToProduct);

            const productHeader = productWrap.querySelector(".h3");

            if (productHeader) {
                productHeader.addEventListener("click", goToProduct);
            }
        }
    });
}

init();
